<template>
  <b-overlay
    :show="loading"
    :variant="darkMode?'dark':'white'"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="md"
  >
    <div>
      <div class="misc-inner p-2 p-sm-3 mt-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">
            Multiple Accounts 🕵🏻‍♀️
          </h2>
          <p class="mb-1">
            Your are associated with more than one school account, select a school to proceed.
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <b-col
          v-for="(account,i) in accounts"
          :key="i"
          xl="3"
          md="3"
          sm="6"
        >
          <statistic-card-vertical
            class=""
            icon="HomeIcon"
            :statistic="account.alias || 'N/A'"
            :statistic-title="account.title"
            color="primary"
            @click.native="(e)=>selectAccount(account.id)"
          />
        </b-col>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BCol, BOverlay } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { GET_ACTIVE_ACCOUNTS_Q } from '@/graphql/queries'
import { SET_ACTIVE_ACCOUNT_M } from '@/graphql/mutations'
import logData from '@/libs/log'
import showToast from '@/lenon/mixins/showToast'
import requiredData from '@/lenon/mixins/requiredData'
import useAppConfig from '@core/app-config/useAppConfig'

const { skin } = useAppConfig()
export default {
  name: 'AccountsVue',
  components: {
    BCol,
    StatisticCardVertical,
    BOverlay,
  },
  mixins: [showToast, requiredData],
  data() {
    return {
      accounts: [],
      loading: false,
    }
  },
  computed: {
    darkMode() {
      return skin.value === 'dark'
    },
  },
  mounted() {
    this.getActiveAccounts()
  },
  methods: {
    getActiveAccounts() {
      this.$apollo.query({
        query: GET_ACTIVE_ACCOUNTS_Q,
      }).then(res => {
        this.accounts = res.data.activeAccounts
      }).catch(err => {
        logData(err)
      })
    },
    selectAccount(schoolId) {
      this.loading = true;
      this.$apollo.mutate({
        mutation: SET_ACTIVE_ACCOUNT_M,
        variables: { id: schoolId, remember_me: this.$store.getters['auth/rememberMe'] },
      }).then(res => {
        this.$store.commit("auth/clearAuthState")
        if (res.data.setActiveAccount) {
          this.$store.commit('auth/setUserData', res.data.setActiveAccount)
          localStorage.setItem('role', res.data?.setActiveAccount?.role)
          localStorage.setItem('permissions', JSON.stringify(res.data?.setActiveAccount?.permissions))
          const permissions = this.$store.getters['auth/formattedPermissions']
          if (permissions) {
            this.$ability.update(permissions)
          }
          this.fetchRequiredData().then(() => {
            this.showSuccess('Just a moment...')
            setTimeout(() => {
              this.submitting = false
              window.location = '/'
            }, 2000)
          }).finally(() => {
          })
        } else {
          this.showError('Failed to set active term, please try again')
        }
      }).catch(err => {
        logData(err)
      }).finally(()=>{

      })
    },
  },
}
</script>
<!--<style lang="scss">-->
<!--//@import "~hover.css";-->
<!--.hvr-underline-from-left:before {-->
<!--  background: #fa2 !important;-->
<!--}-->
<!--</style>-->
